import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import * as React from 'react';

import { Site } from '../../components/shells/site';
import { Seo } from '../../components/utilities/seo';

type Properties = {
    data: any;
};

const Page = ({ data }: Properties): React.ReactElement => {
    return (
        <Site>
            <Seo title="Recepten" />

            {data.allContentfulRecipe.edges.map(({ node }) => (
                <div className={'mb-8'}>
                    <GatsbyImage image={getImage(node.photo)} alt={`${node.title} door Fitfoodforty`} className={'w-64'} />
                    <p>
                        {node.title} - <Link to={node.gatsbyPath}>Link</Link>
                    </p>
                </div>
            ))}
        </Site>
    );
};

Page.propTypes = {
    data: PropTypes.object.isRequired,
};

export const pageQuery = graphql`
    query {
        allContentfulRecipe {
            edges {
                node {
                    gatsbyPath(filePath: "/recepten/{ContentfulRecipe.slug}")
                    title
                    slug
                    photo {
                        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 424, height: 212)
                    }
                }
            }
        }
    }
`;

export default Page;
